// @ts-check
/* eslint-env browser */

import { Vibrant } from "./libs/node-vibrant.js";
import * as SettingsRememberer from "./settings-rememberer.js";
import { nightcorePlayer } from "./nightcore-player.js";

import "./components/app-button/app-button.js";
import "./components/app-header/app-header.js";
import "./components/status-text/status-text.js";
import "./components/play-queue/play-queue.js";
import "./components/footer-player/footer-player.js";
import "./components/patreon-user/patreon-user.js";
import "./components/version-number.js";
import "./components/range-slider/range-slider.js";

import "./patreon.js";

import { isPatron } from "./helpers.js";

let hasAlertedDetuneNotAvailable = false;

// -- VIEW --

function removeAllAds() {
	// @ts-ignore
	const pengarElements = [...document.querySelectorAll(".pengar")];

	for (const pengarElement of pengarElements) {
		pengarElement.remove();
	}
}

async function maybeRemoveAds() {
	const supportingApp = await isPatron();

	if (supportingApp) {
		removeAllAds();
	}
}

maybeRemoveAds();

const $ = (q) => document.querySelector(q);

const DOMRefs = {
	rememberSettingButton: $("#btn-remember-setting"),
	pitchRange: $("#pitch-range"),
	detuneRange: $("#detune-range"),
	volumeRange: $("#volume-range"),
};

let currentFileName;

/**
 *
 * @param {File} file
 */
function readMediaTagsFromFile(file) {
	function pictureDataToURL(picture) {
		if (!picture) {
			return undefined;
		}

		const byteArray = new Uint8Array(picture.data);
		const blob = new Blob([byteArray], { type: picture.format });

		return URL.createObjectURL(blob);
	}

	return new Promise((resolve) => {
		// @ts-expect-error - Script tag
		jsmediatags.read(file, {
			onSuccess: (result) => {
				let title = file.name;
				let artist = "Unknown artist";
				let picture;

				if (result.tags?.title) {
					title = result.tags.title;
				}

				if (result.tags?.artist) {
					artist = result.tags.artist;
				}

				if (result.tags?.picture) {
					picture = result.tags.picture;
				}

				const imageURL = pictureDataToURL(picture);

				const fileNameMatch = file.name.match(/(.+)[\s]-[\s](.+)[.]/i);

				if (fileNameMatch !== null) {
					[, artist, title] = fileNameMatch;
				}

				resolve({ title, artist, imageURL });
			},
			onError: () => {
				try {
					const [, artist, title] = file.name.match(/(.+)[\s]-[\s](.+)[.]/i);

					resolve({ title, artist });
				} catch (e) {
					resolve({ title: file.name, artist: "" });
				}
			},
		});
	});
}

function waitForScripts() {
	return new Promise((resolve) => {
		let mediaTagsLoaded;

		const cid = setInterval(() => {
			mediaTagsLoaded = "jsmediatags" in window;

			if (mediaTagsLoaded) {
				clearInterval(cid);
				resolve();
			}
		}, 100);
	});
}

/**
 * @param {File[]} files
 */
async function openFile(files) {
	await waitForScripts();

	const filesToMediaTags = await Promise.all(
		[...files].map(readMediaTagsFromFile),
	);

	const items = [...files]
		.map((file, i) => ({
			fileName: file.name,
			url: URL.createObjectURL(file),
			...filesToMediaTags[i],
		}))
		.map((object) => ({
			...object,
			id: object.url.split("/").pop(),
		}));

	document.dispatchEvent(
		new CustomEvent("play-queue:add", {
			detail: { items },
		}),
	);
}

document.addEventListener("play-queue:play-song", (event) => {
	if (event instanceof CustomEvent) {
		playSongFromQueue(event.detail);
	}
});

async function playSongFromQueue(playQueueItem) {
	if (!playQueueItem.url) {
		return;
	}

	const response = await fetch(playQueueItem.url);
	const file = await response.blob();

	if (playQueueItem.title !== undefined && playQueueItem.artist !== undefined) {
		nightcorePlayer.currentMetadata = `${playQueueItem.artist} - ${playQueueItem.title}`;
	} else {
		nightcorePlayer.currentMetadata = playQueueItem.fileName.split(".")[0];
	}

	const currentImage = playQueueItem.imageURL || "img/missing-cover-2x.png";

	nightcorePlayer.currentImage = currentImage;

	const palette = await Vibrant.from(currentImage)
		.getPalette()
		.catch(console.error);

	if (palette) {
		const colours = [
			palette.LightVibrant || palette.Vibrant,
			palette.Vibrant,
			palette.DarkMuted,
		].map(({ r, g, b }) => {
			const rgb = [r, g, b].map(Math.floor);

			return `rgb(${rgb.join(",")})`;
		});

		document.dispatchEvent(
			new CustomEvent("player:colours", {
				detail: {
					colours,
				},
			}),
		);
	}

	if (nightcorePlayer.playing) {
		nightcorePlayer.pause();
	}

	// Reset state
	currentFileName = playQueueItem.fileName;
	nightcorePlayer.updateState({ pausedAt: null });

	const storedSetting = SettingsRememberer.getStoredSettings(
		playQueueItem.fileName,
	);

	if (storedSetting) {
		const { playbackRate, detune } = storedSetting;

		nightcorePlayer.playbackRate = Number.parseFloat(playbackRate);
		nightcorePlayer.detune = Number.parseInt(detune, 10);
	}

	document.querySelector("audio").src = URL.createObjectURL(file);

	nightcorePlayer.loadAudioFromElement();

	const reader = new FileReader();

	reader.onload = () => {
		// @ts-ignore
		const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

		const readerResult = reader.result;

		if (readerResult instanceof ArrayBuffer) {
			audioCtx.decodeAudioData(readerResult, (audioBuffer) => {
				if (audioBuffer instanceof AudioBuffer) {
					audioCtx.close();

					nightcorePlayer.loadAudio({
						audioBuffer,
						playToo: true,
					});
				} else {
					console.error("Did not get AudioBuffer from decodeAudioData.");
				}
			});
		} else {
			console.error("Did not get ArrayBuffer from FileReader.");
		}
	};

	reader.readAsArrayBuffer(file);
}

function reactToPlayStateChange(playState) {
	switch (playState) {
		case "PLAYING":
		case "PAUSED":
			document.body.classList.add("has-media");
			break;
		case "ENDED":
			document.dispatchEvent(new CustomEvent("play-queue:request-next-song"));
			break;
		default:
			break;
	}

	document.dispatchEvent(
		new CustomEvent("player:play-state-change", {
			detail: {
				playState,
			},
		}),
	);
}

async function maybeBootFromCache() {
	if (document.location.hash.indexOf("#shared-audio") === -1) {
		return;
	}

	async function _getCachedMediaMetadata() {
		const cache = await caches.open("nightcoreApp");
		const requests = await cache.keys();

		return Promise.all(
			[...requests].reverse().map(async (request) => {
				const response = await cache.match(request);

				return {
					contentType: response.headers.get("content-type"),
					src: request.url,
				};
			}),
		);
	}
	const cachedMediaMetadataPromise = _getCachedMediaMetadata();

	const cachedMetadata = await cachedMediaMetadataPromise;

	return cachedMetadata.map((mt) => ({
		fileName: mt.src.split("/").pop(),
		url: mt.src,
		title: mt.src.split("/").pop(),
		artist: "Unknown",
		id: btoa(mt.src),
	}));
}

function registerEventListeners() {
	const { pitchRange, detuneRange, volumeRange, rememberSettingButton } =
		DOMRefs;

	/** @type {(arg0: CustomEvent) => void} */
	const pitchRangeInputHandler = (event) => {
		nightcorePlayer.playbackRate = event.detail / 100;
	};

	/** @type {(arg0: CustomEvent) => void} */
	const detuneRangeInputHandler = (event) => {
		nightcorePlayer.detune = event.detail;
	};

	/** @type {(arg0: CustomEvent) => void} */
	const volumeRangeInputHandler = (event) => {
		nightcorePlayer.volume = event.detail / 200;
	};

	pitchRange.addEventListener("input-value", pitchRangeInputHandler, {
		passive: true,
	});
	detuneRange.addEventListener("input-value", detuneRangeInputHandler, {
		passive: true,
	});
	volumeRange.addEventListener("input-value", volumeRangeInputHandler, {
		passive: true,
	});

	document.addEventListener("player:no-detune", () => {
		if (!hasAlertedDetuneNotAvailable) {
			// eslint-disable-next-line no-alert
			alert(
				"Your browser does not support detune. :( Try Firefox or Google Chrome.",
			);
			$(".detune-controls").remove();
			hasAlertedDetuneNotAvailable = true;
		}
	});

	nightcorePlayer.playStateObserver.observe((playState) =>
		reactToPlayStateChange(playState),
	);
	nightcorePlayer.currentMetadataObserver.observe((currentMetadata) => {
		document.dispatchEvent(
			new CustomEvent("metadata-change", {
				detail: {
					currentMetadata,
				},
			}),
		);
	});

	document.addEventListener("app-header:open-files", (event) => {
		if (event instanceof CustomEvent && "files" in event.detail) {
			openFile(event.detail.files);
		}
	});

	maybeBootFromCache()
		.then((items) =>
			document.dispatchEvent(
				new CustomEvent("play-queue:add", {
					detail: { items },
				}),
			),
		)
		.catch((e) => console.error(e));

	rememberSettingButton.addEventListener(
		"click",
		() => {
			const { playbackRate, detune } = nightcorePlayer;

			SettingsRememberer.storeSettings(currentFileName, {
				playbackRate,
				detune,
			});

			const contentBefore = String(rememberSettingButton.textContent);

			rememberSettingButton.innerHTML = "Saved! :)";

			setTimeout(() => {
				rememberSettingButton.innerHTML = contentBefore;
			}, 1000);
		},
		false,
	);
}

registerEventListeners();

SettingsRememberer.storeSettings(
	"Elevate Promotions - [PROMO] Dance All Night.mp3",
	{ playbackRate: 1.28, detune: 0 },
);

// @ts-ignore
/*
if (window.ga && window.performance) {
  const timeSincePageLoad = Math.round(performance.now());

  // @ts-ignore
  window.plausible('JS Dependencies', {
    props: {
      load: timeSincePageLoad
    }
  });
}
*/
